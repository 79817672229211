import React from "react"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import CardActionArea from "@material-ui/core/CardActionArea"
import Typography from "@material-ui/core/Typography"

interface IProps {
  service: Service
}

interface Service {
  title: string
  image: string
  description: string
  link: string
}

const ServiceCard = ({ service }: IProps) => {
  return (
    <Card>
      <CardActionArea>
        <a
          href={service.link}
          style={{ color: "inherit", textDecoration: "inherit" }}
        >
          <CardContent
            style={{
              textAlign: "start",
              padding: 16,
              minHeight: 160,
              display: "flex",
            }}
          >
            <div style={{ display: "flex", justifyContent: "center" }}>
              <img
                alt={service.title}
                src={service.image}
                style={{ width: 50, paddingRight: 20 }}
              />
              <div style={{ display: "flex", alignItems: "center" }}>
                <div>
                  <Typography variant="h6" color="inherit">
                    {service.title}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="p"
                  >
                    {service.description}
                  </Typography>
                </div>
              </div>
            </div>
          </CardContent>
        </a>
      </CardActionArea>
    </Card>
  )
}
export default ServiceCard
