import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/hero"
import Grid from "@material-ui/core/Grid"
import ServiceCard from "../components/service-card"
import ThemeProvider from "@material-ui/styles/ThemeProvider"
import theme from "../theme"
import { services } from "../config"
import Typography from "@material-ui/core/Typography"

const IndexPage = () => (
  <React.Fragment>
    <ThemeProvider theme={theme}>
      <Layout>
        <SEO title="Home" />
        <Hero />
        <Grid
          container
          spacing={3}
          style={{
            textAlign: "center",
            minHeight: 200,
            padding: 10,
          }}
        >
          {services.map(service => (
            <Grid item xs={12} sm={4} key={service.id}>
              <ServiceCard service={service} />
            </Grid>
          ))}
        </Grid>
        <Typography
          variant="body1"
          color="inherit"
          style={{ marginTop: "60px", textAlign: "center" }}
        >
          BARIAMED est un site d’information à destination des médecins généralistes, dont l’objectif est de
          favoriser la compréhension du suivi des patients après une chirurgie bariatrique.
          <strong>Le contenu du site ne se substitue pas à la responsabilité de prescription et de décision du médecin.</strong>
        </Typography>
      </Layout>
    </ThemeProvider>
  </React.Fragment>
)

export default IndexPage
